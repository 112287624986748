import axios from "axios";
import * as vars from "../../Constants";
import { HeadersReq } from "../../Types/Global.types";
import * as types from "../../Types/WaterTracker.types";

// Request utilites as object to fetch data from the back end
const Request = {
  /*
   * Get achievements
   */
  getAchievements: async (headers: HeadersReq) => {
    const response = await axios.get(
      `${vars.BACK_END_WATER_TRACKER}/get-achievements/`,
      headers
    );
    return response.data as types.Achievements;
  },

  /*
   * Get month data
   */
  getMonthData: async (arg: {
    req: types.MonthDataGetter;
    headers: HeadersReq;
  }) => {
    const { req, headers } = arg;
    const response = await axios.get(
      `${vars.BACK_END_WATER_TRACKER}/get-month?year=${req.year}&month=${req.month}`,
      headers
    );
    return response.data as types.Month;
  },

  /**
   * Get control values
   */
  getControlValues: async (headers: HeadersReq) => {
    const response = await axios.get(
      `${vars.BACK_END_WATER_TRACKER}/get-control-values/`,
      headers
    );
    return response.data as types.Controls;
  },

  /**
   * Get single day data
   */
  getDayData: async (arg: {
    req: types.DayDataGetter;
    headers: HeadersReq;
  }) => {
    const { req, headers } = arg;
    const response = await axios.get(
      `${vars.BACK_END_WATER_TRACKER}/get-day?year=${req.year}&month=${req.month}&date=${req.date}`,
      headers
    );
    return response.data as types.SingleDayData;
  },

  /**
   * Update single day data
   */
  updateDayAmount: async (arg: {
    payloadData: types.AmountUpdater;
    headers: HeadersReq;
  }) => {
    const { payloadData, headers } = arg;
    const response = await axios.put(
      `${vars.BACK_END_WATER_TRACKER}/update-daily-amount`,
      payloadData,
      headers
    );
    return response.data as types.SingleDayData;
  },

  /**
   * Set daily goal
   */
  setDailyGoal: async (arg: {
    payloadData: types.GoalUpdater;
    headers: HeadersReq;
  }) => {
    const { payloadData, headers } = arg;
    const response = await axios.put(
      `${vars.BACK_END_WATER_TRACKER}/set-daily-goal`,
      payloadData,
      headers
    );
    return response.data as types.GoalUpdater;
  },

  /**
   * Set control value
   */
  setControlValue: async (arg: {
    payloadData: types.SetControlValue;
    headers: HeadersReq;
  }) => {
    const { payloadData, headers } = arg;
    const response = await axios.put(
      `${vars.BACK_END_WATER_TRACKER}/set-control-value/`,
      payloadData,
      headers
    );
    return response.data as types.SetControlValue;
  },

  /**
   * Set control values
   */
  setControlValues: async (arg: {
    payloadData: types.ControlUpdater;
    headers: HeadersReq;
  }) => {
    const { payloadData, headers } = arg;
    const response = await axios.put(
      `${vars.BACK_END_WATER_TRACKER}/set-control-values/`,
      payloadData,
      headers
    );
    return response.data as types.ControlUpdater;
  },

  /**
   * Set control values: amount and type
   */
  setControlsAmountAndType: async (arg: {
    payloadData: { amount: number; type: string };
    headers: HeadersReq;
  }) => {
    const { payloadData, headers } = arg;
    const response = await axios.put(
      `${vars.BACK_END_WATER_TRACKER}/set-controls-amount-and-type/`,
      payloadData,
      headers
    );
    return response.data as types.Controls;
  },

  /**
   * Reset last drink action
   */
  resetLastDrink: async (arg: {
    payloadData: types.ResetLastDrink;
    headers: HeadersReq;
  }) => {
    const { payloadData, headers } = arg;
    const response = await axios.put(
      `${vars.BACK_END_WATER_TRACKER}/amount-step-backwards`,
      payloadData,
      headers
    );
    return response.data as types.SingleDayData;
  },
};

export { Request };
