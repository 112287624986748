import React from "react";
import { CircularProgress } from "@mui/material";
import classes from "./Charts.module.scss";

const ChartIsLoading: React.FC = () => {
  return (
    <div className={`${classes["loading-container"]}`}>
      <CircularProgress />
    </div>
  );
};

/**
 * No Data Element
 */
const NoData: React.FC<{ emptyMessage: string }> = ({ emptyMessage }) => {
  return (
    <div className={`${classes["no-data-container"]}`}>
      <svg
        className={`${classes["icon"]} my-3`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
      >
        <path d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0l1.8 0c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z" />
      </svg>

      <h4 className={`${classes["no-data-text"]}`}>{emptyMessage}</h4>
    </div>
  );
};

export { ChartIsLoading, NoData };
