import { createAsyncThunk } from "@reduxjs/toolkit";
import { Request } from "./Request.utils";
import * as types from "../../Types/WaterTracker.types";
import { HeadersReq } from "../../Types/Global.types";

/**
 * Async thunk for fetch month data
 */
const getMonthData = createAsyncThunk<
  types.Month | null,
  { req: types.MonthDataGetter; headers: HeadersReq }
>("data/getMonthData", Request.getMonthData);

/**
 * Async thunk for fetching single day data
 */
const getTodayData = createAsyncThunk<
  types.SingleDayData | null,
  { req: types.DayDataGetter; headers: HeadersReq }
>("data/getTodayData", Request.getDayData);

/**
 * Async thunk for updating amount of a single day
 */
const updateDayAmount = createAsyncThunk<
  types.SingleDayData | null,
  { payloadData: types.AmountUpdater; headers: HeadersReq }
>("data/update-daily-amount", Request.updateDayAmount);

/**
 * Async thunk for setting daily goal value
 */
const setDailyGoal = createAsyncThunk<
  types.GoalUpdater | null,
  { payloadData: types.GoalUpdater; headers: HeadersReq }
>("data/update-daily-goal", Request.setDailyGoal);

/**
 * Async thunk for setting a control value
 */
const setControlValue = createAsyncThunk<
  types.SetControlValue | null,
  { payloadData: types.SetControlValue; headers: HeadersReq }
>("data/setControlValue", Request.setControlValue);

/**
 * Async thunk for setting control values: amount and type. Date and goal are day data related.
 */
const setControlValues = createAsyncThunk<
  types.ControlUpdater | null,
  { payloadData: types.ControlUpdater; headers: HeadersReq }
>("data/setControlValues", Request.setControlValues);

/**
 * Async thunk for setting a control value
 */
const setControlsAmountAndType = createAsyncThunk<
  types.Controls | null,
  { payloadData: { amount: number; type: string }; headers: HeadersReq }
>("data/setControlsAmountAndType", Request.setControlsAmountAndType);

/**
 * Async thunk for setting a control value
 */
const getControlValues = createAsyncThunk<types.Controls | null, HeadersReq>(
  "data/getControlValues",
  Request.getControlValues
);

/**
 * Async thunk for getting achievements
 */
const getAchievements = createAsyncThunk<types.Achievements, HeadersReq>(
  "data/getAchievements",
  Request.getAchievements
);

/**
 * Initializing water tracker
 */
const initialize = createAsyncThunk<
  types.Initializer,
  { req: types.DayDataGetter; headers: HeadersReq }
>("data/initialize", async ({ req, headers }) => {
  return {
    control: await Request.getControlValues(headers),
    month: await Request.getMonthData({ req, headers }),
    achievements: await Request.getAchievements(headers),
  } as types.Initializer;
});

/*
 * Async thunk to reset last drink action
 */
const resetLastDrink = createAsyncThunk<
  types.SingleDayData | null,
  { payloadData: types.ResetLastDrink; headers: HeadersReq }
>("data/resetLastDrink", Request.resetLastDrink);

// TODO: Create async thunk for registering a drink and create a state for loading

export {
  updateDayAmount,
  getMonthData,
  getTodayData,
  getControlValues,
  initialize,
  setDailyGoal,
  resetLastDrink,
  setControlValue,
  setControlValues,
  setControlsAmountAndType,
  getAchievements,
};
